<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.log_package") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div>
        <v-simple-table fixed-header class="table-padding-2">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.type") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.quantity") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id" class="text-center">
                <td>{{ item.barcode }}</td>
                <td>{{ item.quantity }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "LogOrderPackage",
  props: {
    idOrder: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    items: [],
  }),
  mounted() {
    this.getList();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async getList() {
      const { data } = await httpClient.post("/order-online-get-package", {
        id_order: this.idOrder,
      });
      this.items = [...data];
    },
  },
};
</script>

<style scoped></style>
